<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="6"
    >
      <v-form @submit="next">
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Overenie telefónneho čísla a emailovej adresy</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-row v-if="!user.verified_sms">
              <v-col>
                <v-text-field
                  v-model="form_sms_code.sms_code"
                  label="SMS kód"
                  name="sms_code"
                  prepend-icon="mdi-phone"
                  type="text"
                />
              </v-col>
              <v-col>
                <v-btn
                  class="my-3"
                  color="primary"
                  @click="verifySMS"
                >
                  Overiť SMS kód
                </v-btn>
              </v-col>
            </v-row>
            <div
              class="text-center"
              v-else
            >
              SMS kód bol overený <v-icon color="success">mdi-check</v-icon>
            </div>
            <v-row v-if="!user.verified_email">
              <v-col>
                <v-text-field
                  v-model="form_email_code.email_code"
                  label="Emailový kód"
                  name="email_code"
                  prepend-icon="mdi-email"
                  type="text"
                />
              </v-col>
              <v-col>
                <v-btn
                  class="my-3"
                  color="primary"
                  @click="verifyEmail"
                >
                  Overiť emailový kód
                </v-btn>
              </v-col>
            </v-row>
            <div
              class="text-center"
              v-else
            >
              Email bol overený <v-icon color="success">mdi-check</v-icon>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!user.verified_email || !user.verified_sms"
              @click="next"
            >
              Pokračovať
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";

const apiService = new APIService();

export default {
  props: {
    source: String,
  },
  data() {
    return {
      drawer: null,
      form_email_code: {
        email_code: ""
      },

      form_sms_code: {
        sms_code: ""
      },

      user: {

      }
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },

  mounted: function () {
    this.getMe()
  },


  methods: {
    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
      });
    },

    next() {
      this.$router.push({ "path": "/bank_verify" });
    },

    verifySMS() {
      apiService.verifySMS(this.form_sms_code).then((response) => {
        this.user = response;
        if (!this.user.verified_sms) {
          alert("Nesprávny SMS kód");

        }
      });
    },

    verifyEmail() {
      apiService.verifyEmail(this.form_email_code).then((response) => {
        this.user = response;
        if (!this.user.verified_email) {
          alert("Nesprávny Emailový kód");
        }
      });
    }
  },
};
</script>